import { Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React, { useEffect, memo } from 'react';
import RectangularLoading from '../../../loading/RectangularLoading';
import { GuidanceChunk } from '../../../../redux/models/dataModelTypes';
import { useInfiniteQuery } from '@tanstack/react-query'

const SEARCH_RESULT_LIMIT = 50;


async function fetchGuidanceChunkPage(
    searchText: string,
    guidanceFilter: Array<[string, string]>,
    organizationId: string,
    limit: number,
    page: number,
    builderDocumentId?: string,
): Promise<{
    rows: Array<GuidanceChunk>;
    nextPage: number;
}> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/guidance_chunks/search`,
        {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                search_text: searchText,
                guidance_filter: guidanceFilter,
                builder_document_id: builderDocumentId ?? null,
                organization_id: organizationId,
                page: page,
                limit: limit
            })
        }
    )

    let jsonResponse = await response.json();
    let hasNext = jsonResponse["metadata"]["has_next"];
    return {
        rows: jsonResponse["data"],
        nextPage: hasNext ? (page + 1) : -1
    };
}

interface GuidanceSearchResultsProps {
    guidanceFilter: Array<[string, string]>;
    organizationId: string;
    builderDocumentId?: string;
    searchedValue: string;
    isSearchResultDirty: boolean;
    onSelectGuidanceChunk: (guidanceChunk: GuidanceChunk) => void;
}

const GuidanceSearchResults = ({
    guidanceFilter,
    organizationId,
    builderDocumentId,
    searchedValue,
    isSearchResultDirty,
    onSelectGuidanceChunk
}: GuidanceSearchResultsProps) => {
    const {
        status,
        data: guidanceChunksData,
        refetch: refetchGuidanceChunks,
        isFetching: isFetchingGuidanceChunks,
        isRefetching: isRefetchingGuidanceChunks,
        // TODO: uncomment when pagination is implemented
        // isFetchingNextPage,
        // fetchNextPage,
        // hasNextPage,

    } = useInfiniteQuery({
        queryKey: [
            'GuidanceChunks',
            (builderDocumentId === undefined ? 'OrganizationSearchResult' : 'BuilderDocumentSearchResult'),
            (builderDocumentId === undefined ? organizationId : builderDocumentId)
        ],
        queryFn: (ctx) => fetchGuidanceChunkPage(
            searchedValue,
            guidanceFilter,
            organizationId,
            SEARCH_RESULT_LIMIT,
            ctx.pageParam,
            builderDocumentId
        ),
        getNextPageParam: (lg) => lg.nextPage === -1 ? undefined : lg.nextPage,
        initialPageParam: 0,
        enabled: searchedValue.length > 0
    });

    useEffect(() => {
        if (isSearchResultDirty && searchedValue.length > 0) {
            refetchGuidanceChunks();
        }
    }, [searchedValue]);

    if (searchedValue.length === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <List
                        component="nav"
                        dense
                        disablePadding
                    >
                        <ListItem>
                            <ListItemText
                                primary="Enter a query to search"
                                primaryTypographyProps={{
                                    textAlign: 'center'
                                }}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        );
    }

    if (status === "pending" || isFetchingGuidanceChunks || isRefetchingGuidanceChunks) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <List
                        component="nav"
                        dense
                        disablePadding
                    >
                        <ListItem>
                            <RectangularLoading />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        );
    }

    const allGuidanceChunks = guidanceChunksData ? guidanceChunksData.pages.flatMap((d) => d.rows) : [];

    return (
        <Grid container>
            <Grid item xs={12}>
                <List
                    component="nav"
                    dense
                    disablePadding
                >
                    {isSearchResultDirty && (
                        <ListItem>
                            <ListItemText
                                primary="Showing Stale Results"
                                primaryTypographyProps={{
                                    textAlign: 'center'
                                }}
                            />
                        </ListItem>
                    )}
                    {(allGuidanceChunks.length === 0) ? (
                        <ListItem>
                            <ListItemText primary="No results" />
                        </ListItem>
                    ) : (allGuidanceChunks.map((result, i) => (
                        <ListItemButton
                            key={i}
                            onClick={() => onSelectGuidanceChunk(result)}
                        >
                            <ListItemText
                                primary={result.contentAlias}
                                secondary={result.textContent}
                                secondaryTypographyProps={{
                                    noWrap: true,

                                }}
                            />
                        </ListItemButton>
                    )))}
                    {(allGuidanceChunks.length === SEARCH_RESULT_LIMIT) && (
                        <ListItem>
                            <ListItemText
                                secondary="Max Results Shown"
                                secondaryTypographyProps={{
                                    textAlign: 'center'
                                }}
                            />
                        </ListItem>
                    )}
                </List>
            </Grid>
        </Grid>
    );
};

export default memo(GuidanceSearchResults);