import React from "react";
import { CommentType } from "../../../../redux/models/dataModelTypes";
import DocumentHighlightMeta from "./DocumentHighlightMeta";
import KeyTermMeta from "./KeyTermMeta";
import ReportSectionMeta from "./ReportSectionMeta"

interface CommentMetadataProps {
    commentType: CommentType;
    commentMetadata: Record<string, any>;
    onClick: () => void;
    highlightOnInit?: boolean;
}

function CommentMetadata({
    commentType,
    commentMetadata,
    onClick,
    highlightOnInit = false
}: CommentMetadataProps) {
    return (
        <>
            {commentType === "DOCUMENT_HIGHLIGHT" && (
                <DocumentHighlightMeta
                    commentMetadata={commentMetadata}
                    onClick={onClick}
                    highlightOnInit={highlightOnInit}
                />
            )}
            {commentType === "KEY_TERM" && (
                <KeyTermMeta 
                    commentMetadata={commentMetadata} 
                    onClick={onClick}
                />
            )}
            {commentType === "REPORT_SECTION" && (
                <ReportSectionMeta
                    commentMetadata={commentMetadata}
                    onClick={onClick}
                />
            )}
        </>
    );
}

export default CommentMetadata;
