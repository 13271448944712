import React, { useEffect, useState } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { getGridColumnHeaderElement } from '@mui/x-data-grid/utils/domUtils';

const renderSubTrees = (
    depth: number,
    indexEntries: any[]
) => {
    const treeItems = []
    for (let i = 0; i < indexEntries.length; i++) {
        const indexEntry = indexEntries[i];
        if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
            treeItems.push(
                <TreeItem
                    itemId={indexEntry['id']}
                    label={indexEntry['label']}
                >
                    <>
                        {renderSubTrees(depth + 1, indexEntry['nested'])}
                    </>
                </TreeItem>
            );
        } else {
            treeItems.push(
                <TreeItem
                    itemId={indexEntry['id']}
                    label={indexEntry['label']}
                />
            );
        }
    }
    return treeItems;
}

interface GuidanceOutlineItemProps {
    guidanceId: string;
    labeledRefs: any[];
}

const GuidanceOutlineItem = ({
    guidanceId,
    labeledRefs
}: GuidanceOutlineItemProps) => {
    const {
        guidanceOutlineOpen,
        researchPanelBasicState,
        selectedGuidanceId,
        selectedGuidanceItemId,
        setSelectedGuidanceItemId,
    } = useResearchPanelData();

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    useEffect(() => {
        // check if this particular guidance is currently selected and visible
        if (researchPanelBasicState.isOpen 
            && researchPanelBasicState.currentTab === "GUIDANCE"
            && guidanceOutlineOpen 
            && guidanceId === selectedGuidanceId
        ) {
            const iterate = (ids: string[], ref: any) => {
                if (selectedGuidanceItemId === ref['id']) {
                    setExpandedItems(ids);
                }
                for (let i = 0; i < ref['nested'].length; i++) {
                    iterate([...ids, ref['id']], ref['nested'][i]);
                }
            }
    
            for (let i = 0; i < labeledRefs.length; i++) {
                iterate([], labeledRefs[i]);
            }
        }
    }, [
        guidanceId,
        guidanceOutlineOpen,
        researchPanelBasicState.currentTab,
        researchPanelBasicState.isOpen,
        selectedGuidanceId
    ]);

    return (
        <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={(e, itemIds) => {
                setExpandedItems(itemIds);
            }}
            selectedItems={selectedGuidanceItemId}
            onItemSelectionToggle={(e, itemId) => {
                const headerElem = document.getElementById(itemId);
                if (headerElem) {
                    headerElem.scrollIntoView({
                        block: "center",
                        inline: "center",
                        behavior: "smooth"
                    });
                }
                setSelectedGuidanceItemId(itemId);
            }}
            itemChildrenIndentation={0}
        >
            {renderSubTrees(0, labeledRefs)}
        </SimpleTreeView>
    );
};

export default GuidanceOutlineItem;