import React, { useContext, PropsWithChildren, useMemo, memo } from 'react';
import { BuilderHolder } from "../../types/builder";
import { 
  useGetBuilderDocumentQuery, 
  useGetDefaultTakerDocumentSettingsQuery 
} from '../../redux/services/builder';
import { TakerDocumentSettings } from '../../redux/models/dataModelTypes';
import GuidanceData from '../GuidanceData';
import { Module } from '../../types/builderv2.generated';

interface BuilderDataHookData {
  builderHolder: BuilderHolder;
  tableLabelLookup: Record<string, string>;
  keyTerms: Array<{ label: string, description: string }>;
  positionedModuleList: { path: string[]; module: Module; }[];
  defaultTakerDocumentSettings?: TakerDocumentSettings;
}

type BuilderDataHook = () => BuilderDataHookData;

const Context = React.createContext({});

export const useReadOnlyBuilderData: BuilderDataHook = () => {
  return useContext(Context) as BuilderDataHookData;
}

interface ReadOnlyBuilderDataContainerProps {
  builderDocumentId: string;
}

const ReadOnlyBuilderDataContainer: React.FC<PropsWithChildren<ReadOnlyBuilderDataContainerProps>> = ({
  builderDocumentId,
  children
}) => {
  const { data: builderDocument } = useGetBuilderDocumentQuery(builderDocumentId);
  const { data: defaultTakerDocumentSettings } = useGetDefaultTakerDocumentSettingsQuery(builderDocumentId);

  const builderHolder = useMemo(() => {
    if (builderDocument) {
      return new BuilderHolder(builderDocument.configuration);
    }
  }, [builderDocument]);

  const builderHolderData = useMemo(() => {
    if (builderHolder) {
      return {
        builderHolder: builderHolder,
        keyTerms: builderHolder.getKeyTerms(),
        positionedModuleList: builderHolder.getPositionedModuleList()
      }
    }
  }, [builderHolder]);

  const builderDocumentGuidances = useMemo(() => {
    if (!builderDocument) {
      return [];
    }
    return builderDocument.builderDocumentGuidances;
  }, [builderDocument]);

  if (!builderHolderData) {
    return null;
  }

  return (
    <Context.Provider 
      value={{
        ...builderHolderData,
        defaultTakerDocumentSettings
      }}
    >
      <GuidanceData guidanceReferences={builderDocumentGuidances.map((bdg) => ({ 
        indexedGuidance: bdg.indexedGuidance, 
        displayMode: bdg.displayMode,
        indexedGuidanceVersion: bdg.indexedGuidanceVersion,
        canCite: bdg.canCite
      }))}>
        {children}
      </GuidanceData>.
    </Context.Provider>
  );
};

export default memo(ReadOnlyBuilderDataContainer);
