import { useParams, useSearchParams } from "react-router-dom";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress, 
    Divider, 
    Drawer, 
    FormHelperText, 
    Grid, 
    Menu, 
    MenuItem, 
    Select, 
    Stack,
    TextField, 
    Typography
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import {
    useGetIndexedGuidanceQuery,
    useUpdateIndexedGuidanceMutation,
    useUpsertIndexedGuidanceContentMutation,
    useLazyGetIndexedGuidanceContentQuery,
    useCreateIndexedGuidancePackageMutation,
    useAddIndexedGuidanceParsingJobMutation
} from "../../redux/services/indexedGuidance";
import { useEffect, useMemo, useState, useCallback } from "react";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { Add, HighlightAlt, Save } from "@mui/icons-material";
import RichTextEditor from "../../components/lexical/wrappers/RichTextEditor";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import ExportGuidancePackageButton from "./ExportGuidancePackageButton";
import UpdateIndexedGuidanceDetails from "./UpdateIndexedGuidanceDetails";
import GuidancePdfHighligher from "./GuidancePdfHighligher";
import { flushSync } from "react-dom";
import { DocumentAnnotation } from "../../types/taker/documentkeyterms.generated";
import LabeledRefsHolder from "./labedRefsHolder";
import { TreeItemClickPayload, renderSubTrees } from "./GuidanceTreeView";
import AutoScalingInput from "./AutoScalingInput";
import { IndexedGuidance } from "../../redux/models/dataModelTypes";
import IndexGuidancePackageButton from "./IndexGuidancePackageButton";

const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

interface IndexedGuidanceInnerComponentProps {
    indexedGuidance: IndexedGuidance;
};

const IndexedGuidanceInnerComponent = ({ indexedGuidance }: IndexedGuidanceInnerComponentProps) => {
    const [updateIndexedGuidance, updateIndexedGuidanceRes] = useUpdateIndexedGuidanceMutation();
    const [upsertIndexedGuidanceContent, upsertIndexedGuidanceContentRes] = useUpsertIndexedGuidanceContentMutation();
    const [trigger] = useLazyGetIndexedGuidanceContentQuery();
    const [createIndexedGuidancePackage, createIndexedGuidancePackageRes] = useCreateIndexedGuidancePackageMutation();

    const [labeledRefs, setLabeledRefs] = useState<any[]>([]);

    const [editingLabel, setEditingLabel] = useState<string>();
    const [editingHtmlContent, setEditingHtmlContent] = useState<string>();
    const [editingLexicalContent, setEditingLexicalContent] = useState<string>();
    const [editingContent, setEditingContent] = useState<string>();

    // maintain the selected entry in the search params
    const [searchParams, setSearchParams] = useSearchParams();
    const [targetEntryId, setTargetEntryId] = useState<string>(searchParams.get("_target_entry_id") ?? "");
    useEffect(() => {
        if (targetEntryId === "") {
            setEditingHtmlContent(undefined);
            setEditingLexicalContent(undefined);
            setEditingContent(undefined);
        } else {
            startAddContentToSelected(targetEntryId);
        }

        // sync the search params
        setSearchParams({ "_target_entry_id": targetEntryId });
    }, [targetEntryId]);

    const targetEntryData: TreeItemClickPayload | undefined = useMemo(() => {
        if (targetEntryId === "") {
            return;
        }

        const iter = (depth: number, indexEntries: any[]): TreeItemClickPayload | undefined => {
            for (let i = 0; i < indexEntries.length; i++) {
                if (indexEntries[i]['id'] === targetEntryId) {
                    const isFirst = (i === 0);
                    const isLast = (i === indexEntries.length - 1);
                    const isTopLevel = (depth === 0);
                    const hasNoSiblings = (indexEntries.length === 1);
                    return {
                        targetEntry: indexEntries[i],
                        targetEntryIsFirst: isFirst,
                        targetEntryIsLast: isLast,
                        targetEntryIsTopLevel: isTopLevel,
                        targetEntryHasNoSiblings: hasNoSiblings
                    };
                }
                const res = iter((depth + 1), indexEntries[i]['nested']);
                if (res !== undefined) {
                    return res;
                }
            }
        };
        return iter(0, labeledRefs);
    }, [
        targetEntryId,
        labeledRefs
    ]);

    const [selectedNodeId, setSelectedNodeId] = useState<string>();
    const selectedEntryData: TreeItemClickPayload | undefined = useMemo(() => {
        if (selectedNodeId === undefined) {
            return;
        }

        const iter = (depth: number, indexEntries: any[]): TreeItemClickPayload | undefined => {
            for (let i = 0; i < indexEntries.length; i++) {
                if (indexEntries[i]['id'] === selectedNodeId) {
                    const isFirst = (i === 0);
                    const isLast = (i === indexEntries.length - 1);
                    const isTopLevel = (depth === 0);
                    const hasNoSiblings = (indexEntries.length === 1);
                    return {
                        targetEntry: indexEntries[i],
                        targetEntryIsFirst: isFirst,
                        targetEntryIsLast: isLast,
                        targetEntryIsTopLevel: isTopLevel,
                        targetEntryHasNoSiblings: hasNoSiblings
                    };
                }
                const res = iter((depth + 1), indexEntries[i]['nested']);
                if (res !== undefined) {
                    return res;
                }
            }
        };
        return iter(0, labeledRefs);
    }, [
        selectedNodeId,
        labeledRefs
    ]);

    const [contentInputState, setContentInputState] = useState<null | "HEADER" | "CONTENT" | "EDIT_HEADER">(null);
    const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
    const [indexedGuidanceName, setIndexedGuidanceName] = useState<string>();
    const [needsSaveName, setNeedsSaveName] = useState<boolean>(false);
    const [documentWidth, setDocumentWidth] = useState<number>();

    const [targetEntryTextContent, setTargetEntryTextContent] = useState<string[]>([]);

    const [movingUnderSibilingHeader, setMovingUnderSibilingHeader] = useState<boolean>(false);
    const [sibilingHeaderEntryId, setSibilingHeaderEntryId] = useState<undefined | string>();

    const [anchorEl, setAnchorEl] = useState<Element>();
    const menuOpen = Boolean(anchorEl);

    const [selectedElementId, setSelectedElementId] = useState<string>();
    const [selectedHighlightedIds, setSelectedHighlightedIds] = useState<string[]>([]);

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    const { user } = useSelector((state: RootReducerType) => state.auth);

    const [parseGuidanceJob, parseGuidanceJobRes] = useAddIndexedGuidanceParsingJobMutation();

    const hasElevatedPermissions = useMemo(
        () => user && user.roles.includes("SUPERADMIN"),
        [user]
    );

    const isUploadingPackage = useMemo(
        () => createIndexedGuidancePackageRes.isLoading,
        [createIndexedGuidancePackageRes]
    );

    const assetUrl: string | undefined = useMemo(() => {
        if (!indexedGuidance) {
            return
        }
        let upload = indexedGuidance.fileUpload;
        if (upload) {
            return `${API_ENDPOINT}/file_uploads/${upload.id}/items/${upload.fileUploadItems[0].id}/content`;
        }
    }, [indexedGuidance]);

    const latestJob = useMemo(() => {
        if (!indexedGuidance || !indexedGuidance.parsingJobs) {
            return;
        }
        let jobs = [...indexedGuidance.parsingJobs];
        jobs.sort((a, b) => b.createdAt - a.createdAt);
        return jobs[0];
    }, [indexedGuidance.parsingJobs]);

    useEffect(() => {
        if (indexedGuidance?.referenceMap['labeledRefs']) {
            flushSync(() => {
                setLabeledRefs(indexedGuidance?.referenceMap['labeledRefs']);
            });
        }
    }, [indexedGuidance.referenceMap]);

    useEffect(() => {
        if (upsertIndexedGuidanceContentRes.isSuccess) {
            let nodeId = upsertIndexedGuidanceContentRes.originalArgs?.id;
            const updateLabeledRef = (indexEntries: any[]) => {
                for (const indexEntry of indexEntries) {
                    if (indexEntry['id'] === nodeId) {
                        indexEntry['hasContent'] = true;
                    } else {
                        updateLabeledRef(indexEntry['nested']);
                    }
                }
            };

            if (indexedGuidance) {
                let newLabeledRefs = JSON.parse(JSON.stringify(labeledRefs));
                updateLabeledRef(newLabeledRefs);
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    guidanceId: indexedGuidance.guidanceId,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    canonicalLabel: indexedGuidance.canonicalLabel,
                    referenceMap: {
                        'labeledRefs': newLabeledRefs
                    }
                });
                flushSync(() => {
                    setLabeledRefs(newLabeledRefs);
                });
            }
        }
    }, [upsertIndexedGuidanceContentRes]);

    const targetEntryLabel: string | undefined = useMemo(() => {
        if (!targetEntryData) {
            return undefined;
        }

        const crawl = (labeledRef: any): string[] => {
            if (labeledRef['id'] === targetEntryData.targetEntry['id']) {
                return [labeledRef['label']];
            }

            for (const lr of labeledRef['nested']) {
                const labels = crawl(lr);
                if (labels.length > 0) {
                    const newLabels = [labeledRef['label'], ...labels];
                    return newLabels;
                }
            }
            return [];
        };

        for (const lr of labeledRefs) {
            const label = crawl(lr);
            if (label.length > 0) {
                return label.join("  >  ");
            }
        }
    }, [
        labeledRefs,
        targetEntryData?.targetEntry
    ]);

    const selectedEntryLabel: string | undefined = useMemo(() => {
        if (!selectedEntryData) {
            return undefined;
        }

        const crawl = (labeledRef: any): string[] => {
            if (labeledRef['id'] === selectedEntryData.targetEntry['id']) {
                return [labeledRef['label']];
            }

            for (const lr of labeledRef['nested']) {
                const labels = crawl(lr);
                if (labels.length > 0) {
                    const newLabels = [labeledRef['label'], ...labels];
                    return newLabels;
                }
            }
            return [];
        };

        for (const lr of labeledRefs) {
            const label = crawl(lr);
            if (label.length > 0) {
                return label.join("  >  ");
            }
        }
    }, [
        labeledRefs,
        selectedEntryData?.targetEntry
    ]);

    const selectedNodeHeaderTagIds: string[] = useMemo(() => {
        if (selectedNodeId) {
            const tagIds: string[] = []
            const updateLabeledRef = (indexEntries: any[]) => {
                for (const entry of indexEntries) {
                    if (entry['id'] === selectedNodeId) {
                        tagIds.push(...(entry['label_tag_ids'] ?? []));
                        break;
                    }
                    updateLabeledRef(entry['nested']);
                }
            };
            updateLabeledRef(labeledRefs);
            return tagIds;
        }
        return [];
    }, [
        selectedNodeId, 
        labeledRefs
    ])

    const sibilingSelectOptions: any[] = useMemo(() => {
        if (selectedNodeId !== undefined) {
            const options: any[] = [];
            const populateOptions = (indexEntries: any[]) => {
                let entries = [];
                let flag = false;
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === selectedNodeId) {
                        flag = true;
                    } else {
                        entries.push(indexEntry);
                    }
                    populateOptions(indexEntry['nested']);
                }

                if (flag) {
                    for (const e of entries) {
                        options.push({
                            id: e["id"],
                            display: e["label"]
                        });
                    }
                }
            };

            populateOptions(labeledRefs);
            return options;
        }
        return [];
    }, [
        labeledRefs,
        selectedNodeId
    ]);

    const removeHeaderToSelected = (thisEntryId: string) => {
        if (thisEntryId !== "") {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] !== thisEntryId) {
                        indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                        entries.push(indexEntry);
                    }
                }
                return entries;
            };

            let newLabeledRefs = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
            updateIndexedGuidance({
                id: indexedGuidance.id,
                guidanceId: indexedGuidance.guidanceId,
                name: indexedGuidance.name,
                description: indexedGuidance.description,
                canonicalLabel: indexedGuidance.canonicalLabel,
                referenceMap: {
                    'labeledRefs': newLabeledRefs
                }
            });
            setLabeledRefs(newLabeledRefs);
            cancelUpdate();
        }
    };

    const startAddContentToSelected = (thisEntryId: string) => {
        if (thisEntryId !== undefined || thisEntryId !== "") {
            setContentInputState("CONTENT");

            // load the content for a given entry if it exists
            trigger({
                id: thisEntryId,
                indexedGuidanceId: indexedGuidance.id
            }).then((res) => {
                if (res.isSuccess) {
                    let indexedGuidanceContent = res.data;
                    setEditingHtmlContent(indexedGuidanceContent?.htmlContent);
                    setEditingLexicalContent(indexedGuidanceContent?.lexicalContent);
                    setEditingContent(indexedGuidanceContent?.content);
                } else {
                    setEditingLexicalContent("");
                }
            }).catch((error) => {
                console.error(error);
                setEditingLexicalContent("");
            });
        }
    };

    const moveHeaderUp = (thisEntryId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.moveHeaderUp(thisEntryId);
        saveIndexedGuidance(holder.labeledRefs);
        cancelUpdate();
    };

    const moveHeaderDown = (thisEntryId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.moveHeaderDown(thisEntryId);
        saveIndexedGuidance(holder.labeledRefs);
        cancelUpdate();
    };

    const moveIntoParentHeader = (thisEntryId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.moveIntoParentHeader(thisEntryId);
        saveIndexedGuidance(holder.labeledRefs);
        cancelUpdate();
    };

    const moveUnderSiblingHeader = (thisEntryId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        if (sibilingHeaderEntryId === undefined) {
            return;
        }
        holder.moveUnderSiblingHeader(thisEntryId, sibilingHeaderEntryId);
        saveIndexedGuidance(holder.labeledRefs);
        cancelUpdate();
    };

    const cancelUpdate = () => {
        setContentInputState(null);
        setNeedsUpdate(false);
        setTargetEntryId("");
        setMovingUnderSibilingHeader(false);
        setSibilingHeaderEntryId(undefined);
        setEditingLabel(undefined);
    };

    const markAsReviewed = (thisEntryId: string, isReviewed: boolean) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.markAsReviewed(thisEntryId, isReviewed);
        saveIndexedGuidance(holder.labeledRefs);
        cancelUpdate();
    };

    const addContentTagIds = (thisEntryId: string, contentTagIds: string[]) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.addContentTagIds(thisEntryId, contentTagIds);
        saveIndexedGuidance(holder.labeledRefs);
    };

    const addHeaderTagIds = (thisEntryId: string, labelTagIds: string[]) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.addHeaderTagIds(thisEntryId, labelTagIds);
        saveIndexedGuidance(holder.labeledRefs);
    };

    const removeHeaderTagId = (thisEntryId: string, tagId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.removeHeaderTagId(thisEntryId, tagId);
        saveIndexedGuidance(holder.labeledRefs);
    };

    const removeContentTagId = (thisEntryId: string, tagId: string) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.removeContentTagId(thisEntryId, tagId);
        saveIndexedGuidance(holder.labeledRefs);
    };

    const updateAliases = (thisEntryId: string, altIds: string[]) => {
        let holder = new LabeledRefsHolder(labeledRefs);
        holder.updateAliases(thisEntryId, altIds);
        saveIndexedGuidance(holder.labeledRefs);
    };

    const saveIndexedGuidance = useCallback((newLabeledRefs: any[]) => {
        updateIndexedGuidance({
            id: indexedGuidance.id,
            guidanceId: indexedGuidance.guidanceId,
            name: indexedGuidance.name,
            description: indexedGuidance.description,
            canonicalLabel: indexedGuidance.canonicalLabel,
            referenceMap: {
                'labeledRefs': newLabeledRefs
            }
        });
        flushSync(() => {
            setLabeledRefs(newLabeledRefs);
        });
    }, [indexedGuidance]);

    const doContentSave = () => {
        if (indexedGuidance) {
            upsertIndexedGuidanceContent({
                id: targetEntryId,
                indexedGuidanceId: indexedGuidance.id,
                content: editingContent,
                htmlContent: editingHtmlContent,
                lexicalContent: editingLexicalContent
            });
            cancelUpdate();
        }
    };

    const documentAnnotationsByPage = useMemo(() => {
        const mapping: Record<number, DocumentAnnotation[]> = {};

        const addTags = (tags: string[]) => {
            if (!tags || tags.length === 0) {
                return;
            }

            const samplePage = parseInt(tags[0].split("-")[0]); 
            if (!mapping[samplePage]) {
                mapping[samplePage] = [];
            }
            mapping[samplePage].push({
                annotationId: window.crypto.randomUUID(),
                lexicalDocumentIdentifier: "guidance",
                documentHighlights: tags.map(tag => ({
                    elementType: "TEXTBOX",
                    elementId: tag
                })),
                page: samplePage
            });
        };

        const iterate = (ref: any) => {
            addTags(ref['content_tag_ids']);
            addTags(ref['label_tag_ids']);
            for (let i = 0; i < ref['nested'].length; i++) {
                iterate(ref['nested'][i]);
            }
        }

        for (let i = 0; i < labeledRefs.length; i++) {
            iterate(labeledRefs[i]);
        }
        return mapping;
    }, [labeledRefs]);

    return ( 
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%'
                }}>
                <Grid
                    container
                    direction="row"
                    sx={{ height: '100%' }}
                >
                    <Grid
                        xs={12}
                        sx={{
                            height: '5%',
                            display: 'inline-flex',
                            alignItems: "center"
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Stack
                                direction="row"
                                sx={{
                                    alignItems: "center"
                                }}
                            >
                                <AutoScalingInput
                                    name={indexedGuidanceName || indexedGuidance.name}
                                    onChange={(tn) => {
                                        setIndexedGuidanceName(tn);
                                        setNeedsSaveName(true);
                                    }}
                                />
                                {needsSaveName && (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{ marginLeft: "5px" }}
                                        onClick={() => {
                                            updateIndexedGuidance({
                                                id: indexedGuidance.id,
                                                guidanceId: indexedGuidance.guidanceId,
                                                name: indexedGuidanceName,
                                                description: indexedGuidance.description,
                                                canonicalLabel: indexedGuidance.canonicalLabel,
                                                referenceMap: indexedGuidance.referenceMap
                                            });
                                            setNeedsSaveName(false);
                                        }}
                                    >
                                        save name
                                    </Button>
                                )}
                                <Button
                                    size="small"
                                    variant="outlined"
                                    disabled={contentInputState === "HEADER"}
                                    startIcon={<Add />}
                                    sx={{ marginLeft: "5px" }}
                                    onClick={() => {
                                        setContentInputState("HEADER");
                                    }}
                                >
                                    header
                                </Button>
                                {(hasElevatedPermissions) && (
                                    <>
                                        <UpdateIndexedGuidanceDetails indexedGuidance={indexedGuidance} />
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{ marginLeft: "5px" }}
                                            disabled={isUploadingPackage}
                                            onClick={() => {
                                                createIndexedGuidancePackage(indexedGuidance.id);
                                            }}
                                        >
                                            {isUploadingPackage ? "uploading package" : "create package"}
                                        </Button>
                                        <ExportGuidancePackageButton indexedGuidanceId={indexedGuidance.id} />
                                        <IndexGuidancePackageButton indexedGuidanceId={indexedGuidance.id} />
                                    </>
                                )}
                                {hasElevatedPermissions && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{ marginLeft: "5px" }}
                                        disabled={Boolean(latestJob)}
                                        onClick={() => {
                                            parseGuidanceJob({
                                                indexedGuidanceId: indexedGuidance.id
                                            });
                                        }}
                                    >
                                        process PDF
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        container
                        sx={{
                            height: '90%'
                        }}
                    >
                        <Grid xs={6} sx={{ height: '100%', overflow: "auto" }}>
                            <Box display="grid">
                                <>
                                    <SimpleTreeView
                                        aria-haspopup="true"
                                        aria-expanded={menuOpen ? 'true' : undefined}
                                        expandedItems={expandedItems}
                                        onExpandedItemsChange={handleExpandedItemsChange}
                                        onItemSelectionToggle={(
                                            event: React.SyntheticEvent,
                                            itemId: string,
                                            isSelected: boolean,
                                        ) => {
                                            if (isSelected) {
                                                setSelectedNodeId(itemId);
                                            }
                                            event.stopPropagation();
                                        }}
                                    >
                                        {renderSubTrees(
                                            0,
                                            labeledRefs,
                                            (e, d) => {
                                                setAnchorEl(e);
                                                setSelectedNodeId(d.targetEntry['id']);
                                            },
                                            (d) => {
                                                if (!d.targetEntry['nested'] || d.targetEntry['nested'].length == 0) {
                                                    setTargetEntryId(d.targetEntry['id']);
                                                    startAddContentToSelected(d.targetEntry['id']);

                                                    const labelTags = d.targetEntry['label_tag_ids'];
                                                    if (labelTags && labelTags.length > 0) {
                                                        setSelectedElementId(labelTags[0]);
                                                    }

                                                    const contentTags = d.targetEntry['content_tag_ids'];
                                                    if (contentTags && contentTags.length > 0) {
                                                        setSelectedHighlightedIds(contentTags);
                                                    }
                                                } else {
                                                    console.error(d);
                                                }
                                            },
                                            (d) => {
                                                setSelectedNodeId(d.targetEntry['id']);
                                                markAsReviewed(d.targetEntry['id'], !d.targetEntry['reviewed']);
                                            },
                                            (d) => {
                                                setSelectedNodeId(d.targetEntry['id']);
                                                const labelTags = d.targetEntry['label_tag_ids'];
                                                if (labelTags && labelTags.length > 0) {
                                                    setSelectedElementId(labelTags[0]);
                                                    setSelectedHighlightedIds(labelTags);
                                                }
                                            }
                                        )}
                                    </SimpleTreeView>
                                    {selectedEntryData && (
                                        <Menu
                                            elevation={1}
                                            anchorEl={anchorEl}
                                            open={menuOpen}
                                            onClose={() => setAnchorEl(undefined)}
                                        >
                                            <MenuItem
                                                color="inherit"
                                                disableRipple
                                                onClick={(e: any) => {
                                                    setAnchorEl(undefined);
                                                        
                                                    const selectedId = selectedEntryData.targetEntry['id'];
                                                    if (selectedId !== undefined && selectedId !== "") {
                                                        setContentInputState("EDIT_HEADER");
                                                        setEditingLabel(selectedEntryData.targetEntry['label']);
                                            
                                                        // load the content for a given entry if it exists
                                                        trigger({
                                                            id: selectedId,
                                                            indexedGuidanceId: indexedGuidance.id
                                                        }).then((res) => {
                                                            if (res.isSuccess) {
                                                                let indexedGuidanceContent = res.data;
                                                                setEditingHtmlContent(indexedGuidanceContent?.htmlContent);
                                                                setEditingLexicalContent(indexedGuidanceContent?.lexicalContent);
                                                                setEditingContent(indexedGuidanceContent?.content);
                                                            } else {
                                                                setEditingLexicalContent("");
                                                            }
                                                        }).catch((error) => {
                                                            console.error(error);
                                                            setEditingLexicalContent("");
                                                        });
                                                    }
                                                }}
                                            >
                                                Edit Header
                                            </MenuItem>
                                            {!selectedEntryData.targetEntry['hasContent'] && (
                                                <MenuItem
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        setContentInputState("HEADER");
                                                    }}
                                                >
                                                    Add Header
                                                </MenuItem>
                                            )}
                                            <Divider />
                                            {(!selectedEntryData.targetEntry['nested'] || selectedEntryData.targetEntry['nested'].length == 0) && (
                                                <MenuItem
                                                    color="inherit"
                                                    disableRipple
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        setTargetEntryId(selectedEntryData.targetEntry['id']);
                                                        startAddContentToSelected(selectedEntryData.targetEntry['id']);
                                                    }}
                                                >
                                                    {selectedEntryData.targetEntry['hasContent'] ?
                                                        "Edit Content" :
                                                        "Add Content"
                                                    }
                                                </MenuItem>
                                            )}
                                            <MenuItem
                                                color="danger"
                                                disableRipple
                                                onClick={(e: any) => {
                                                    setAnchorEl(undefined);
                                                    removeHeaderToSelected(selectedEntryData.targetEntry['id']);
                                                }}
                                            >
                                                Remove
                                            </MenuItem>
                                            <Divider />
                                            {!selectedEntryData.targetEntryIsFirst && (
                                                <MenuItem
                                                    color="danger"
                                                    disableRipple
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        moveHeaderUp(selectedEntryData.targetEntry['id']);
                                                    }}
                                                >
                                                    Move Up
                                                </MenuItem>
                                            )}
                                            {!selectedEntryData.targetEntryIsLast && (
                                                <MenuItem
                                                    color="danger"
                                                    disableRipple
                                                    disabled={selectedEntryData.targetEntryIsLast}
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        moveHeaderDown(selectedEntryData.targetEntry['id']);
                                                    }}
                                                >
                                                    Move Down
                                                </MenuItem>
                                            )}
                                            {!selectedEntryData.targetEntryIsTopLevel && (
                                                <MenuItem
                                                    color="danger"
                                                    disableRipple
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        moveIntoParentHeader(selectedEntryData.targetEntry['id']);
                                                    }}
                                                >
                                                    Move Into Parent Header
                                                </MenuItem>
                                            )}
                                            {!selectedEntryData.targetEntryHasNoSiblings && (
                                                <MenuItem
                                                    color="danger"
                                                    disableRipple
                                                    onClick={(e: any) => {
                                                        setAnchorEl(undefined);
                                                        setMovingUnderSibilingHeader(true);
                                                    }}
                                                >
                                                    Move Under Sibling Header
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    )}
                                </>
                            </Box>
                            <Drawer
                                variant="persistent"
                                anchor="left"
                                open={contentInputState === "CONTENT"}
                                onClose={() => {
                                    cancelUpdate();
                                }}
                                sx={{
                                    zIndex: 10000
                                }}
                            >
                                <Box
                                    marginTop="48px"
                                    maxWidth="50vw"
                                    padding={1}
                                >
                                    <Box display="flex" alignItems="center" paddingBottom={1}>
                                        <Typography variant="h6" alignItems="center">
                                            {targetEntryLabel}
                                            {(editingHtmlContent === "") && (
                                                <Chip label="No HTML" size="small" />
                                            )}
                                            <Button
                                                size="small"
                                                sx={{ marginLeft: 1 }}
                                                startIcon={<HighlightAlt />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (targetEntryData) {
                                                        const labelTags = targetEntryData.targetEntry['label_tag_ids'];
                                                        if (labelTags && labelTags.length > 0) {
                                                            setSelectedHighlightedIds(labelTags);
                                                            setSelectedElementId(labelTags[0]);
                                                        }
                                                    }
                                                }}
                                            >
                                                Header
                                            </Button>
                                            <Button
                                                size="small"
                                                startIcon={<HighlightAlt />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (targetEntryData) {
                                                        const contentTags = targetEntryData.targetEntry['content_tag_ids'];
                                                        if (contentTags && contentTags.length > 0) {
                                                            setSelectedHighlightedIds(contentTags);
                                                            setSelectedElementId(contentTags[0]);
                                                        }
                                                    }
                                                }}
                                            >
                                                Content
                                            </Button>
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Autocomplete
                                            size='small'
                                            multiple
                                            fullWidth
                                            options={[]}
                                            value={(targetEntryData?.targetEntry['alt_ids'] || []) as string[]}
                                            onChange={(event, newValue) => {
                                                if (targetEntryId !== "") {
                                                    updateAliases(targetEntryId, newValue);
                                                }
                                            }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""
                                                    placeholder="Aliases"
                                                    data-testid="alternate-ids"
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box sx={{ paddingTop: "10px" }}>
                                        {(editingLexicalContent !== undefined) ? (
                                            <RichTextEditor
                                                contentLexical={editingLexicalContent}
                                                onUpdate={
                                                    (content, htmlContent, lexicalContent) => {
                                                        setEditingContent(content);
                                                        setEditingHtmlContent(htmlContent);
                                                        setEditingLexicalContent(lexicalContent)
                                                        setNeedsUpdate(true);
                                                    }
                                                }
                                                withDiff={targetEntryTextContent}
                                            />
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </Box>
                                    <Box sx={{ paddingTop: "10px" }}>
                                        <Button
                                            variant="outlined"
                                            disabled={!needsUpdate}
                                            startIcon={<Save />}
                                            sx={{ marginLeft: "5px" }}
                                            onClick={() => doContentSave()}
                                        >
                                            save content
                                        </Button>
                                        <Button
                                            sx={{ marginLeft: "5px" }}
                                            variant="outlined"
                                            onClick={cancelUpdate}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </Drawer>
                        </Grid>
                        <Grid
                            xs={6}
                            sx={{ height: '100%' }}
                            ref={(ref) => {
                                if (ref) {
                                    setDocumentWidth(ref.offsetWidth);
                                }
                            }}
                        >
                            {((latestJob?.state === "IN_PROGRESS") || (documentWidth === undefined)) && (
                                <CircularProgress />
                            )}
                            {(!assetUrl) && (
                                <span>no reference document</span>
                            )}
                            {(!latestJob) && (
                                <span>must process document</span>
                            )}
                            {(assetUrl && latestJob && documentWidth) && (
                                <GuidancePdfHighligher
                                    readOnly={!hasElevatedPermissions}
                                    assetUrl={assetUrl}
                                    indexedGuidanceJob={latestJob}
                                    documentWidth={documentWidth}
                                    documentAnnotationsByPage={documentAnnotationsByPage}
                                    selectedElementId={selectedElementId}
                                    selectedHighlightedIds={selectedHighlightedIds}
                                    onTextContentChange={(words) => {
                                        setTargetEntryTextContent(words);
                                    }}
                                    targetEntryId={targetEntryId === "" ? selectedNodeId : targetEntryId}                                            
                                    targetEntryContentTagIds={
                                        targetEntryData?.targetEntry['label_tag_ids'] ?
                                            targetEntryData?.targetEntry['content_tag_ids'] : []
                                    }
                                    targetEntryHeaderTagIds={
                                        targetEntryData?.targetEntry['label_tag_ids'] ?? selectedNodeHeaderTagIds 
                                    }
                                    onAddContentTagIds={addContentTagIds}
                                    onAddHeaderTagIds={addHeaderTagIds}
                                    onRemoveHeaderTagId={removeHeaderTagId}
                                    onRemoveContentTagId={removeContentTagId}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <SimpleModalWrapper
                headerText={selectedEntryLabel ? ((contentInputState === "HEADER") ? 
                    `Inserting under header "${selectedEntryLabel}"`: 
                    `Editing header "${selectedEntryLabel}"`
                ) : (
                    "Inserting at root"
                )}
                open={contentInputState === "HEADER" || contentInputState === "EDIT_HEADER"}
                handleClose={() => cancelUpdate()}
                maxWidth='md'
            >
                <Box sx={{ paddingTop: "10px" }}>
                    <Box>
                        <TextField
                            fullWidth
                            multiline
                            label="Header Label"
                            placeholder="enter a label for the header"
                            value={editingLabel}
                            onChange={(e) => setEditingLabel(e.target.value)}
                        />
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            textAlign: "right"
                        }}
                    >
                        {(contentInputState === "HEADER") && (
                            <Button
                                variant="outlined"
                                disabled={editingLabel === ""}
                                onClick={() => {
                                    let holder = new LabeledRefsHolder(labeledRefs);
                                    if (editingLabel === undefined || selectedEntryData === undefined) {
                                        return;
                                    }
                                    holder.updateHeader(selectedEntryData.targetEntry['id'], editingLabel);
                                    saveIndexedGuidance(holder.labeledRefs);
                                    cancelUpdate();
                                }}
                            >
                                Add
                            </Button>
                        )}
                        {(contentInputState === "EDIT_HEADER") && (
                            <Button
                                variant="outlined"
                                disabled={editingLabel === ""}
                                onClick={() => {
                                    let holder = new LabeledRefsHolder(labeledRefs);
                                    if (editingLabel === undefined || selectedEntryData === undefined) {
                                        return;
                                    }
                                    holder.updateHeaderInPlace(selectedEntryData.targetEntry['id'], editingLabel);
                                    saveIndexedGuidance(holder.labeledRefs);
                                    cancelUpdate();
                                }}
                            >
                                Update
                            </Button>
                        )}
                        <Button
                            sx={{ marginLeft: "5px" }}
                            variant="outlined"
                            onClick={cancelUpdate}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </SimpleModalWrapper>
            <SimpleModalWrapper
                headerText="Inserting Under Sibling"
                open={movingUnderSibilingHeader}
                handleClose={() => cancelUpdate()}
                maxWidth='md'
            >
                <Box sx={{ paddingTop: "10px" }}>
                    <Box>
                        <Select
                            fullWidth
                            size="small"
                            sx={{ minWidth: "200px" }}
                            label=""
                            defaultValue={sibilingHeaderEntryId}
                            onChange={(e: any) => {
                                setSibilingHeaderEntryId(e.target.value);
                            }}>
                            {sibilingSelectOptions.map((o) =>
                                <MenuItem value={o["id"]}>
                                    {o["display"]}
                                </MenuItem>
                            )}
                        </Select>
                        <FormHelperText>
                            This will move your header after the last child header of the selected sibiling header
                        </FormHelperText>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "20px",
                            textAlign: "right"
                        }}
                    >
                        <Button
                            variant="outlined"
                            disabled={!sibilingHeaderEntryId}
                            onClick={() => {
                                if (selectedEntryData) {
                                    moveUnderSiblingHeader(selectedEntryData.targetEntry['id']);
                                }
                            }}
                        >
                            Move
                        </Button>
                        <Button
                            sx={{ marginLeft: "5px" }}
                            variant="outlined"
                            onClick={cancelUpdate}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

const IndexedGuidanceComponent = () => {
    const { id } = useParams<{ id: any }>();
    const {
        data: indexedGuidance,
        isFetching,
        isError
    } = useGetIndexedGuidanceQuery(id);

    if (isFetching) {
        return (
            <div style={{ padding: 2 }}>
                <CircularProgress />
            </div>
        );
    } else if (!indexedGuidance || isError) {
        return (
            <div style={{ padding: 2 }}>
                <Typography>
                    No indexed guidance found
                </Typography>
            </div>
        );
    }
    return (
        <SideNav>
            <IndexedGuidanceInnerComponent
                key={indexedGuidance.id}
                indexedGuidance={indexedGuidance} 
            />
        </SideNav>
    );
};

export default IndexedGuidanceComponent;

