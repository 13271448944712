import React from "react";
import { useCallback } from "react";
import { Typography } from "@mui/material/";
import BoxWithHoverBg from "./BoxWithHoverBg";

interface ReportSectionMetaProps {
    commentMetadata: Record<string, any>;
    onClick: () => void;
}

const namespacePrettyMap: Record<string, string> = {
    "key_terms_table": "Key Terms Table",
    "variable": "Variable",
    "document_details": "Document Details",
    "summary": "Summary",
    "outline": "Outline",
    "expanded": "Expanded Section"
}

function ReportSectionMeta({
    commentMetadata,
    onClick
}: ReportSectionMetaProps) {
    const {
        editIdentifier,
        sectionNamespace
    } = commentMetadata;

    const onReportSectionMetaClick = useCallback(() => {
        const sectionElement = document.getElementById(editIdentifier);
        if (sectionElement !== null) {
            sectionElement.scrollIntoView({ behavior: "smooth" });
        }
    }, [])

    return (
        <BoxWithHoverBg
            data-testid="key-term-meta"
            width="100%"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onReportSectionMetaClick();
                onClick();
            }}
        >
            <Typography
                    variant="body2"
                    color="text.primary"
                    noWrap
                    data-testid="comments-drawer-group-doc-title"
                >
                    {sectionNamespace && namespacePrettyMap[sectionNamespace] ? <strong>Report - {namespacePrettyMap[sectionNamespace]}</strong> : <strong>Report</strong>}
            </Typography>
        </BoxWithHoverBg>
    );
}

export default ReportSectionMeta;
