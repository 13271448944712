import { Box, Typography, ToggleButtonGroup, ToggleButton, IconButton, Toolbar, Drawer, Stack } from "@mui/material";
import { APP_BAR_HEIGHT } from '../navigation/SideNav';
import { AutoStories, KeyboardTab } from "@mui/icons-material";
import { useResearchPanelData } from "../../containers/ResearchPanelData/ResearchPanelData";
import Guidance from "./GuidanceMain";
import AdvisorHeader from "./Advisor/AdvisorHeader";
import AdvisorFlow from "./Advisor/AdvisorFlow";
import AdvisorResponses from "./Advisor/AdvisorWorkflowResponses";

export const ResearchPanel = () => {
    const {
        researchPanelBasicState,
        setResearchPanelBasicState,
        setGuidanceOutlineOpen
    } = useResearchPanelData();
    return (
        <>
            <Drawer
                open={researchPanelBasicState.isOpen}
                variant="persistent"
                anchor="right"
                onClose={() => {
                    setResearchPanelBasicState({
                        ...researchPanelBasicState,
                        isOpen: false
                    })
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#FBFCFE",
                        overflow: 'hidden',
                    },
                    elevation: 16
                }}
            >
                <Stack
                    marginTop={`${APP_BAR_HEIGHT}px`}
                    width="50vw"
                    height={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
                    overflow="hidden"
                >
                    <Box borderBottom="1px solid #e0e0e0">
                        <Toolbar
                            variant="dense"
                            sx={{
                                width: "100%",
                                display: "inline-grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                alignItems: "center",
                                height: "48px",
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AutoStories sx={{ mr: 1 }} color="primary" />
                                <Typography variant="h6" component="span">Research Tool</Typography>
                            </div>
                            <ToggleButtonGroup
                                size="small"
                                color="primary"
                                exclusive
                                value={researchPanelBasicState.currentTab}
                                onChange={() => {
                                    setResearchPanelBasicState({
                                        ...researchPanelBasicState,
                                        currentTab: researchPanelBasicState.currentTab === 'ADVISOR' ? 'GUIDANCE' : 'ADVISOR',
                                    });
                                    setGuidanceOutlineOpen(false);
                                }}
                            >
                                <ToggleButton value="ADVISOR">Advisor</ToggleButton>
                                <ToggleButton value="GUIDANCE">Guidance</ToggleButton>
                            </ToggleButtonGroup>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton sx={{ ml: 'auto' }}>
                                    <KeyboardTab onClick={() => {
                                        setResearchPanelBasicState({
                                            ...researchPanelBasicState,
                                            isOpen: !researchPanelBasicState.isOpen
                                        })
                                    }} />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Box>
                    <Box
                        padding={1}
                        height="calc(100% - 48px)"
                        display={researchPanelBasicState.currentTab === 'ADVISOR' ? 'flex' : 'none'}
                        flexDirection="column"
                    >
                        <AdvisorHeader />
                        <AdvisorFlow inWorkflow />
                        <AdvisorResponses
                            onLatestPending={() => { }}
                            onLatestComplete={() => { }}
                        />
                    </Box>
                    <Box display={researchPanelBasicState.currentTab === 'GUIDANCE' ? 'block' : 'none'}>
                        <Guidance />
                    </Box>
                </Stack>
            </Drawer>
        </>
    );
};