import React, { useEffect, useMemo, useState } from 'react';
import { Box } from "@mui/material";
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import AdvisorResponse from './AdvisorWorkflowResponse';
import { blue } from '@mui/material/colors';
import { useVirtualizer } from '@tanstack/react-virtual'
import { IdToGuidanceInfo } from './types';
import { useLocation } from 'react-router-dom';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';

interface AdvisorWorkflowResponsesProps {
    onLatestPending: () => void;
    onLatestComplete: () => void;
}

const AdvisorWorkflowResponses = ({
    onLatestPending,
    onLatestComplete
}: AdvisorWorkflowResponsesProps) => {
    const { hash } = useLocation();
    const {
        displayContext,
        researchPanelBasicState,
        guidanceSearchInput,
        setGuidanceSearchInput,
        setResearchPanelBasicState
    } = useResearchPanelData();
    const { activeTakerDocument } = useTakerState();

    const parentRef = React.useRef<HTMLDivElement>(null);

    const sortedContractQATakerDocumentAnalyses = useMemo(() => {
        if (!activeTakerDocument) {
            return [];
        }

        let sortedAnalyses = [...activeTakerDocument.contractQATakerDocumentAnalyses];
        sortedAnalyses.sort((a, b) => b.createdAt - a.createdAt);
        return sortedAnalyses;
    }, [activeTakerDocument]);

    useEffect(() => {
        let latestAnalysis = sortedContractQATakerDocumentAnalyses[0];
        if (latestAnalysis) {
            if (latestAnalysis.state === "PENDING_GENERATION") {
                onLatestPending();
            } else if (latestAnalysis.state === "PENDING_REVIEW") {
                onLatestComplete();
            }
        }
    }, [sortedContractQATakerDocumentAnalyses]);

    const virtualizer = useVirtualizer({
        count: sortedContractQATakerDocumentAnalyses.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 45,
        overscan: 10
    });

    const [idToGuidanceInfo, setIdToGuidanceInfo] = useState<IdToGuidanceInfo>({});

    useEffect(() => {
        // parse _gcid parameter out of the hash
        if (hash.indexOf("_gcid") !== -1) {
            const gcid = hash.substring(hash.indexOf("_gcid") + 6);
            const info = idToGuidanceInfo[gcid];
            if (!info || !info.contentAlias || guidanceSearchInput === info.contentAlias) {
                return;
            }

            setGuidanceSearchInput(info.contentAlias);

            // may need to toggle automatically to the guidance tab
            if (displayContext === "WORKFLOW"
                && researchPanelBasicState.isOpen 
                && researchPanelBasicState.currentTab !== 'GUIDANCE') {
                setResearchPanelBasicState({
                    isOpen: true,
                    currentTab: 'GUIDANCE'
                });
            }
        }
    }, [hash]);

    const items = virtualizer.getVirtualItems();

    return (
        <Box
            flexGrow={1}
            overflow="hidden"
            width="100%"
            sx={{
                backgroundColor: 'white',
                border: `2px solid ${blue[200]}`,
                borderRadius: 1,
            }}
        >
            <div
                ref={parentRef}
                style={{
                    height: '100%',
                    width: '100%',
                    overflowY: 'auto',
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => (
                            <Box
                                key={virtualRow.key}
                                padding={1}
                                component="div"
                                data-index={virtualRow.index}
                                ref={virtualizer.measureElement}
                            >
                                <AdvisorResponse 
                                    key={sortedContractQATakerDocumentAnalyses[virtualRow.index].id}
                                    tduAnalysis={sortedContractQATakerDocumentAnalyses[virtualRow.index]} 
                                    onUpdateMetadata={(m) => {
                                        if (!m?.used_guidance_list || !m?.link_replacement_lookup) {
                                            return;
                                        }

                                        const contentIdToGuidanceInfo: IdToGuidanceInfo = {};
                                        for (const g of m.used_guidance_list) {
                                            contentIdToGuidanceInfo[g.content_id] ={ 
                                                contentId: g.content_id, 
                                                contentAlias: g.content_alias,
                                                guidanceId: g.guidance_id,
                                            };
                                        }

                                        const map: IdToGuidanceInfo = {};
                                        for (const [key, value] of Object.entries(m.link_replacement_lookup)) {
                                            map[key] = contentIdToGuidanceInfo[value];
                                        }
                                        setIdToGuidanceInfo(prev => ({
                                            ...prev,
                                            ...map
                                        }));
                                    }}
                                />
                            </Box>
                        ))}
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default AdvisorWorkflowResponses;
